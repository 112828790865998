/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  margin: 0
}

.no-wrap{
  white-space: nowrap;
}

@import 'front/variable';
@import 'front/theme';

@import 'front/mixins/spacing';
@import 'front/mixins/color';
@import 'front/mixins/grid';
@import 'front/mixins/font';

@import 'front/component/global';
@import 'front/component/heading';

@import 'front/component/header';
@import 'front/component/sidebar';
@import 'front/component/spinner';
@import 'front/component/card';
@import 'front/component/dashboard';

@import 'front/responsive';

/* MODAL */
.cdk-overlay-backdrop {
  &.cdk-overlay-backdrop-showing {
    background: rgba(0, 0, 0, .32) !important;
    opacity: 1 !important;
  }
}

.cdk-drag.list-item {
  border-bottom: 1px solid #6b2394 !important;
}

.cdk-drop-list.list {
  border: 1px solid #6b2394 !important;
}

.position-absolute{
  position: absolute;
}

.position-relative{
  position: relative;
}

.text-align-center {
  text-align: center;
}

@media screen and (max-width: 1318px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    padding: 8px 24px !important;
    white-space: nowrap; //no break line
  }
}

.box-custom{
  display: flex;
  align-items: center;

  &-link{
    margin: 4px 4px 0;
    display: inline-block;
    &-service{
      margin: 1px 5px 0;
    }
  }
}

.flex-row{
  flex-direction: row;
}

.gap-15{
  gap: 15px;
}

.gap-20{
  gap: 20px;
}

.mw-55{
  max-width: 55%;
}

.full-width-table {
  width: 100%;
}

.break-word{
  word-break: break-word;
}

.opacity-0{
  opacity: 0;
}

.justify-end{
  justify-content: end;
}

.mat-table{
  button{
    /*opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;*/
  }

  tr:hover{
    button{
      /*opacity: 1;
      visibility: visible;*/
    }
    td{
      background-color: #f1f1f1 !important;
    }
  }
}
